<template>
  <div>
    <Header :customstyle="{background: '#0C1913'}">
		<template slot="title">转出</template>
	</Header>
    <div class="content">
        <div class="card_top" style="height: 4rem;">
            <div class="title offset">已通过待确认转出</div>
            <div class="topresult">
                <div class="expectpay">
                    <div class="num" >{{ parseFloat(chargeBanner.attBanner).toFixed(3)||0 }}</div>
                    <div style="display: flex;align-items: center;">
                        <div class="txt">ATT</div>
                        <div class="expectpay_button" @click="chargeSubmit(3)">
                            转出
                        </div>
                    </div>

                </div>
              <!--  <div class="expectpay">
                    <div class="num">{{ parseFloat(chargeBanner.arrBanner).toFixed(3)||0 }}</div>
                    <div style="display: flex;align-items: center;">
                        <div class="txt">ARR</div>
                        <div class="expectpay_button" @click="chargeSubmit(2)">
                            转出
                        </div>
                    </div>
                </div>-->
                <div class="expectpay">
                    <div class="num">{{ parseFloat(chargeBanner.usdtBanner).toFixed(3)||0 }}</div>
                    <div style="display: flex;align-items: center;">
                        <div class="txt">USDT</div>
                        <div class="expectpay_button" @click="chargeSubmit(0)">
                            转出
                        </div>
                    </div>
                </div>
                <div class="expectpay">
                    <div class="num" >{{ parseFloat(chargeBanner.btcBanner).toFixed(3)||0 }}</div>
                    <div style="display: flex;align-items: center;">
                        <div class="txt">BTC3.0 LP</div>
                        <div class="expectpay_button" @click="chargeSubmit(4)">
                            转出
                        </div>
                    </div>

                </div>
            </div>
            <!--<div class="topresult">-->
              <!--  <div class="expectpay">
                    <div class="num">{{ parseFloat(chargeBanner.eptBanner).toFixed(3)||0 }}</div>
                    <div style="display: flex;align-items: center;">
                        <div class="txt">EPT</div>
                        <div class="expectpay_button" @click="chargeSubmit(1)">
                            转出
                        </div>
                    </div>
                </div>-->

            <!--</div>-->
        </div>
        <div class="card offset">
            <div class="title offset">请选择支付资产</div>
            <div class="topupnumber">
                <div v-for="currency in currencys" :key="currency">
                    <div :class="sCurreny === currency ? 'selectBtn' : 'btn'" @click="selectCurreny(currency)">
                        {{currency}}
                    </div>
                </div>
            </div>
            <div class="topresult">
                <div class="expectpay"v-if="sCurreny=='ATT'">
                    <div class="num">{{ parseFloat(checkJson.shen_ti).toFixed(2)  }}/{{ parseFloat(walletList[sCurreny]).toFixed(2)||0 }}</div>
                    <div class="txt">可提额度/可用余额({{sCurreny}})</div>
                </div>
                <div class="expectpay" v-else>
                    <div class="num" >{{ walletList[sCurreny]||0 }}</div>
                    <div class="txt">可用余额({{sCurreny}})</div>
                </div>
                <div class="expectpay">
                    <div class="num" v-if="sCurreny=='EPT'">{{ parseFloat(eptBanner).toFixed(2) }}</div>
                    <div class="num" v-if="sCurreny=='ARR'">{{  parseFloat(arrBanner).toFixed(4) }}</div>
                    <div class="num" v-if="sCurreny=='USDT'">{{ parseFloat(usdtBanner).toFixed(2)  }}</div>
                    <div class="num" v-if="sCurreny=='ATT'">{{ parseFloat(attBanner).toFixed(2)  }}</div>
                    <div class="num" v-if="sCurreny=='BTC3.0 LP'">{{ parseFloat(btcBanner).toFixed(2)  }}</div>
                    <div class="txt">钱包余额({{sCurreny}})</div>
                </div>
            </div>
            <div class="inputcontent">
                <van-field class="inputClass" v-model="inputtopup" @keyup="checkNum()"  placeholder="请输入提取数量最小提取数量" />
            </div>
            <div class="paybtn">
                <div class="btn" @click="clickShow($event)">
                    支付
                </div>
            </div>
            <div class="poundage" v-if="sCurreny=='ATT'">
                手续费:
                {{ feeNum }} ATT
                {{ attFeeNum }} BTC3.0 LP
            </div>
            <div class="poundage" v-else>手续费:{{ parseFloat(feeNum).toFixed(4) }}{{sCurreny}}</div>
        </div>
        <div class="middlecontent">
            温馨提示<br>
            1.最小提取数量
            <template v-if="sCurreny === 'ARR'">{{config.withdraw_arr_min}}</template>
            <template v-if="sCurreny === 'USDT'">{{config.withdraw_usdt_min}}</template>
            <template v-if="sCurreny === 'EPT'">{{config.withdraw_ept_min}}</template>
            <template v-if="sCurreny === 'ATT'">{{config.withdraw_et_min}}</template>
            <template v-if="sCurreny === 'BTC3.0 LP'">{{config.withdraw_usdt_min}}</template>

            <template v-if="sCurreny === 'EPT'"> 最大提取数量 {{config.withdraw_ept_max}}</template>
            <template v-if="sCurreny === 'ARR'"> 最大提取数量 {{config.withdraw_arr_max}}</template>
            <template v-if="sCurreny === 'USDT'"> 最大提取数量 {{config.withdraw_usdt_max}}</template>
            <template v-if="sCurreny === 'ATT'"> 最大提取数量 {{config.withdraw_att_max}}</template>
            <template v-if="sCurreny === 'BTC3.0 LP'"> 最大提取数量 {{config.withdraw_usdt_max}}</template>
            <template v-if="sCurreny === 'USDT'"> ，提取手续费{{ parseFloat(config.withdraw_usdt_fee) *100 }}%<br></template>
            <!--<template v-if="sCurreny === 'ATT'"> ，提取手续费{{ parseFloat(config.withdraw_att_fee) *100 }}% ARR<br></template>-->
            <template v-if="sCurreny != 'USDT'&& sCurreny != 'ATT'"> ，提取手续费{{ parseFloat(config.withdraw_fee) *100 }}%<br></template>
            <br> 2.每日提取次数{{config.withdraw_count}}次，提取需要经过区块确认，耐心等待，请勿重复提取，48小时到账
            <!--<template><br>3.贡献值大于32提取；提取一次，贡献值递减1.5个。</template>-->
        </div>
        <div class="record">
            <div class="title">转出记录</div>
            <div class="datalist">
                <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                    <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多记录了"
                    @load="onLoad"
                    >
                        <div class="cell" v-for="(item, index) in recordList" :key="index">
                            <div class="detail" >
                                <div class="topnum">
                                    <div class="num">
                                        {{parseFloat(item.amount).toFixed(3)}}
                                    </div>
                                    <div class="txt">
                                        提取数量({{item.coin_symbol}})
                                    </div>
                                </div>
                                <div class="topnum">
                                    <div class="time">
                                        支付时间：{{item.timestamp}}
                                    </div>
                                    <div class="time" v-if="item.coin_symbol=='ATT'">
                                        手续费：{{parseFloat(item.fee).toFixed(3)}}
                                        ATT
                                        <!--{{(parseFloat(item.amount)*0.0001).toFixed(3)}} ARR-->
                                    </div>
                                    <div class="time" v-else>
                                        手续费：{{parseFloat(item.fee).toFixed(3)}}
                                    </div>
                                </div>
                                <div class="status">
                                    {{ getTransferTxt (item.transfer_status)}}
                                </div>
                            </div>
                        </div>
                    </van-list>
                </van-pull-refresh>
            </div>
        </div>
    </div>
      <van-dialog
              v-model="showpwd"
              title="确认提取"
              @cancel="pwdShow"
              @confirm="clickSubmitSell"
              show-cancel-button
      >
          <van-field
                  type="password"
                  v-model="password"
                  name="安全密码"
                  label="安全密码"
                  placeholder="请输入安全密码"
          ></van-field>
      </van-dialog>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import {myStatistics,getWithdrawConfig, withdrawLog,getWalletInfo,checkWithdraw,withdrawApply} from '@/request/api'
import { ethers } from '../../components/ethers-5.1.esm.min.js'
import metamaskConfig from '../../components/connection.js'
import { Toast } from 'vant';
import $ from 'jquery'

export default {
    components: {
        Header
    },
    computed : {
        getTransferTxt(){
            return val => {
                let txt = ''
                switch (val) {
                    case -1:
                        txt = '失败(拒绝)'
                        break
                    case 0:
                        txt = '等待处理'
                        break
                    case 1:
                        txt = '成功'
                        break
                    case 2:
                        txt = '转账中'
                        break
                }
                return txt
            }
        }
    },
    data () {
        return {
            statistics: {
                devote: 0,
                total: "0.00",
                active: 0,
            },
            showpwd: false,
            password: "",
            sCurreny : 'ATT',
            config: {
                withdraw_arr_min: "0",
                withdraw_ept_min: "0",
                withdraw_et_min: "0",
                withdraw_fee: "0.05",
                withdraw_count: "1",
                withdraw_arr_max:0,
                withdraw_ept_max:0,
                use_lp:0,
            },
            inputtopup:'',
            refreshing: false,
            loading: false,
            finished: false,
            currencys:[
                'ATT','USDT','BTC3.0 LP'
            ],
            recordList: [
                
            ],
            currentPage: 1,
            userInfo: {},
            address:'',
            walletList:{
                'ARR':0,
                'ATT':0,
                'EPT':0,
                'USDT':0,
                'BTC3.0 LP':0,
            },
            eptBanner:0,
            arrBanner:0,
            usdtBanner:0,
            attBanner:0,
            btcBanner:0,
            feeNum:0,
            tokenDisable1:false,
            checkJson:{
                applyArr:0,
                applyAtt:0,
                applyEpt:0,
                applyEt:0,
                shen_ti:0,
            },
            attFeeNum:0,
            chargeBanner:{
                attBanner:0,
                arrBanner:0,
                eptBanner:0,
                usdtBanner:0,
                btcBanner:0,
            },
            withType:0,
        }
    },
    activated() {
        this.address = sessionStorage.getItem("address");
        this.userInfo = sessionStorage.getItem("user")?JSON.parse(sessionStorage.getItem("user")):{};
        this.recordList = []
        this.currency = 1
        this.inputtopup = ''
        this.getConfig()
        if(this.address){
            this.getWalletList();
            this.getBanner();
            this.getmyStatistics();
        }
    },
    methods : {
        getmyStatistics() {
            myStatistics({ address: this.address }).then((res) => {
                this.statistics = res.data;
            });
        },
        async chargeSubmit(type){
            var _this = this;
            if(type==0&&this.chargeBanner.usdtBanner<=0){
                Toast('可提余额为0');
                return false;
            }
            if(type==1&&this.chargeBanner.eptBanner<=0){
                Toast('可提余额为0');
                return false;
            }
            if(type==2&&this.chargeBanner.arrBanner<=0){
                Toast('可提余额为0');
                return false;
            }
            if(type==3&&this.chargeBanner.attBanner<=0){
                Toast('可提余额为0');
                return false;
            }
            if(type==4&&this.chargeBanner.btcBanner<=0){
                Toast('可提余额为0');
                return false;
            }
            if(_this.tokenDisable1){
                return false;
            }
            _this.tokenDisable1 = true;
            try{
                const gasPrice = await metamaskConfig.provider.getGasPrice();
                let res;
                if(type==4){
                    res = await metamaskConfig.tradeContract.tokenWithdrawal(type,{
                        gasPrice: gasPrice,
                        gasLimit:1300000,
                    });
                    if(res){
                        _this.tokenDisable1 = false;
                        Toast('操作成功');
                        setTimeout(function () {
                            location.reload();
                        },2500)
                    }
                }else{
                    res = await metamaskConfig.chargeContract.tokenWithdrawal(type,{
                        gasPrice: gasPrice,
                        gasLimit:1300000,
                    });
                    if(res){
                        _this.tokenDisable1 = false;
                        Toast('操作成功');
                        setTimeout(function () {
                            location.reload();
                        },2500)
                    }
                }


            }catch (e) {
                _this.tokenDisable1 = false;
                Toast('操作失败');
                setTimeout(function () {
                    location.reload();
                },2500)
            }
        },
        clickShow(event){
            var _this = this
            // 判断用户充入数量大于0
            if(this.inputtopup<=0){
                Toast('请输入提取数量');
                return false;
            }
            if(this.sCurreny=='EPT'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyEpt<1){
                    Toast('转出次数不足');
                    return false;
                }
                this.withType=4;
                // Toast("即将开放");
                // return false;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_ept_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_ept_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='ARR'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyArr<1){
                    Toast('转出次数不足');
                    return false;
                }
                this.withType=2;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_arr_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_arr_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='USDT'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyEt<1){
                    Toast('转出次数不足');
                    return false;
                }
                this.withType=3;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_usdt_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_usdt_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='ATT'){
                this.withType = 1;
                // 判断贡献值是否足够
                if( parseFloat(this.statistics['devote'])<=48&&this.userInfo.partner_level_id!=1){
                    Toast('共识度不足48以上');
                    return false;
                }
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum)) > parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyAtt<1){
                    Toast('转出次数不足');
                    return false;
                }
                if(this.checkJson.shen_ti<parseFloat(this.inputtopup)){
                    Toast('用户可提数量不足');
                    return false;
                }
                if(parseFloat(this.attFeeNum)>  parseFloat(this.walletList['BTC3.0 LP'])){
                    Toast('BTC3.0 LP手续费余额不足');
                    return false;
                }
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_et_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_att_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
                // 判断是否金牌银牌
                if(this.userInfo.partner_level_id==2&&this.userInfo.is_gu!=1){
                    Toast("用户需要达标");
                    return false;
                }
                if(this.userInfo.partner_level_id==3&&this.userInfo.is_gu!=1){
                    Toast("用户需要达标");
                    return false;
                }
            }
            else if(this.sCurreny=='BTC3.0 LP'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyEt<1){
                    Toast('转出次数不足');
                    return false;
                }
                this.withType=5;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_usdt_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_usdt_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            if(this.withType==0){
                Toast('请选择资产类型');
                return false;
            }
            this.showpwd = true;
        },
        pwdShow() {
            this.showpwd = false;
        },
        async clickSubmitSell(){
            let _this = this;
            if (!this.password) {
                Toast("请输入密码");
                return;
            }
            const params = {
                address: this.address,
                pay_code: this.password,
                amount: this.inputtopup,
                type: this.withType,
            };
            if(_this.tokenDisable1){
                return false;
            }
            _this.tokenDisable1 = true;
            // if(this.sCurreny=='ATT'){
            //     let etherNum = ethers.utils.parseEther (_this.attFeeNum.toString());
            //     let etherNum1 = ethers.utils.parseEther (_this.inputtopup.toString());
            //     var toggle = event.currentTarget;
            //     $(toggle).css('background','#dddddd');
            //     const gasPrice = await metamaskConfig.provider.getGasPrice();
            //     await metamaskConfig.btcLpContract.allowance(this.address,contractConfig.btclpCon).then(async res =>{
            //         if(parseFloat(ethers.utils.formatEther(res))<=0){
            //             await metamaskConfig.btcLpContract.approve(contractConfig.btclpCon,'0xfffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',{
            //                 gasPrice: gasPrice,
            //                 gasLimit:250000,
            //             });
            //         }
            //         console.log(metamaskConfig.btcConContract);
            //         let tx = await metamaskConfig.btcConContract.Recharge(etherNum,etherNum1,{
            //             gasPrice: gasPrice,
            //             gasLimit:400000,
            //         })
            //         if(tx){
            //             Toast('操作成功');
            //             _this.tokenDisable = false;
            //             setTimeout(function () {
            //                 location.reload();
            //             },3000)
            //
            //         }
            //     })
            //         .catch(err => {
            //         Toast('操作失败')
            //         _this.tokenDisable = false;
            //         return false;
            //     });
            // }else{
                withdrawApply(params).then((res) => {
                    _this.tokenDisable1 = false;
                    if (res.code == 200) {
                        Toast("操作成功");
                        setTimeout(function () {
                            location.reload();
                        },3000)
                    } else {
                        Toast(res.msg);
                    }
                });
            // }
        },
        async getBanner(){
            let _this = this;
            await metamaskConfig.eptContract.balanceOf(this.address).then( re => {
                _this.eptBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.btcLpContract.balanceOf(this.address).then( re => {
                _this.btcBanner = ethers.utils.formatEther(re)
                console.log(_this.btcBanner)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.arrContract.balanceOf(this.address).then( re => {
                _this.arrBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.usdtContract.balanceOf(this.address).then( re => {
                _this.usdtBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.attNewContract.balanceOf(this.address).then( re => {
                _this.attBanner = ethers.utils.formatEther(re)
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.chargeContract.getblance(this.address).then( re => {
                _this.chargeBanner.usdtBanner = ethers.utils.formatEther(re['T1'])
                _this.chargeBanner.eptBanner = ethers.utils.formatEther(re['T2'])
                _this.chargeBanner.arrBanner = ethers.utils.formatEther(re['T3'])
                _this.chargeBanner.attBanner = ethers.utils.formatEther(re['T4'])
            }).catch(err => {
                console.log(err);
                return;
            });
            await metamaskConfig.tradeContract.getblance(this.address).then( re => {
                _this.chargeBanner.btcBanner = ethers.utils.formatEther(re['T5'])
            }).catch(err => {
                console.log(err);
                return;
            });
        },
        async toSubmitWithdraw(event){
            var _this = this

            // 判断用户充入数量大于0
            if(this.inputtopup<=0){
                Toast('请输入提取数量');
                return false;
            }
            let withType = 0;
            if(this.sCurreny=='EPT'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyEpt<1){
                    Toast('转出次数不足');
                    return false;
                }
                withType=3;
                // Toast("即将开放");
                // return false;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_ept_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_ept_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='ARR'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyArr<1){
                    Toast('转出次数不足');
                    return false;
                }
                withType=4;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_arr_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_arr_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='USDT'){
                if((parseFloat(this.inputtopup)+parseFloat(this.feeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyEt<1){
                    Toast('转出次数不足');
                    return false;
                }
                withType=5;
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_usdt_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_usdt_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
            }
            else if(this.sCurreny=='ATT'){
                withType = 1;
                if((parseFloat(this.inputtopup)+parseFloat(this.attFeeNum))> parseFloat(this.walletList[this.sCurreny])){
                    Toast('可提余额不足');
                    return false;
                }
                if(this.checkJson.applyAtt<1){
                    Toast('转出次数不足');
                    return false;
                }
                if(this.checkJson.shen_ti<parseFloat(this.inputtopup)){
                    Toast('用户可提数量不足');
                    return false;
                }
             /*   if(parseFloat(this.feeNum)> parseFloat(this.walletList['ARR'])){
                    Toast('手续费余额不足');
                    return false;
                }*/
                if(parseFloat(this.inputtopup)<parseFloat(this.config.withdraw_et_min)){
                    Toast("小于最小提取数量");
                    return false;
                }
                if(parseFloat(this.inputtopup)>parseFloat(this.config.withdraw_att_max)){
                    Toast("大于最大提取数量");
                    return false;
                }
                // 判断是否金牌银牌
                if(this.userInfo.partner_level_id==2&&this.userInfo.is_gu!=1){
                    Toast("用户需要达标");
                    return false;
                }
                if(this.userInfo.partner_level_id==3&&this.userInfo.is_gu!=1){
                    Toast("用户需要达标");
                    return false;
                }
            }
            if(withType==0){
                Toast('请选择资产类型');
                return false;
            }
            if(_this.tokenDisable1){
                return false;
            }
            _this.tokenDisable1 = true;
            var toggle = event.currentTarget;
            $(toggle).css('background','#dddddd');
            const gasPrice = await metamaskConfig.provider.getGasPrice();
            try{
                let etherNum = ethers.utils.parseEther (_this.inputtopup.toString());
                let res;
                if(this.sCurreny=='ATT'){
                    res = await metamaskConfig.attConContract.ApplyForWithdrawal(etherNum,{
                        // gasPrice: ethers.utils.parseUnits("5", "gwei"),
                        gasPrice: gasPrice,
                        gasLimit:300000,
                    });
                }else{
                    res = await metamaskConfig.conContract.extract(etherNum,withType,{
                        gasPrice: gasPrice,
                        gasLimit:400000,
                    });
                }
                if(res){
                    _this.tokenDisable1 = false;
                    Toast('操作成功');
                    setTimeout(function () {
                        location.reload();
                    },2500)
                }
            }catch (e) {
                _this.tokenDisable1 = false;
                Toast('操作失败');
                setTimeout(function () {
                    location.reload();
                },2500)
            }
        },
        checkNum(){
            this.attFeeNum =0;
            if(parseFloat(this.inputtopup)>0){
                if(this.sCurreny=='USDT'){
                    this.feeNum = parseFloat(this.inputtopup)*parseFloat(this.config.withdraw_usdt_fee);
                }
                else if(this.sCurreny=='ATT'){
                    this.attFeeNum = (parseFloat(this.inputtopup)*parseFloat(this.config.use_lp)).toFixed(4);
                    if(this.userInfo.is_trade==1&&parseFloat(this.userInfo.fee)>0.28){
                        this.feeNum = (parseFloat(this.inputtopup)*0.28).toFixed(4)
                    }else if(this.userInfo.partner_level_id==1){
                        this.feeNum = (parseFloat(this.inputtopup)*0.2).toFixed(4)
                    }
                    else{
                        this.feeNum = (parseFloat(this.inputtopup)*parseFloat(this.userInfo.fee)).toFixed(4)
                    }
                }
                else{
                    this.feeNum = (parseFloat(this.inputtopup)*parseFloat(this.config.withdraw_fee)).toFixed(4);
                }
            }else{
                this.feeNum  = 0;
            }

        },
        selectCurreny (currency) {
            this.sCurreny = currency;
            this.inputtopup = 0;
            this.feeNum = 0;
        },
        getConfig () {
            getWithdrawConfig().then(res => {
                this.config = res.data
            })
        },
        onRefresh() {
            // 清空列表数据
            this.finished = false;
            this.currentPage = 1
            // 重新加载数据
            // 将 loading 设置为 true，表示处于加载状态
            this.loading = true;
            this.onLoad();
        },
        onLoad() {
            if(this.address){
                this.getDataList()
                this.currentPage++;
            }else{
                this.loading = false;
            }
        },
        getWalletList() {
            let _this = this;
            const params = {
                address: this.address
            }
            getWalletInfo(params).then((res)=> {
                $.each(res.data.list,function (i,ele) {
                    _this.walletList[ele.coin_symbol] = parseFloat(ele.cloud_balance).toFixed(4);
                })
                console.log(_this.walletList)
            })
            checkWithdraw(params).then((res)=> {
               if(res.code==200){
                   this.checkJson = res.data;
                   console.log(this.checkJson)
               }
            })
        },
        getDataList() {
            const params = {
                address:this.address,
                page:this.currentPage,
                limit: '20'
            }
            withdrawLog(params).then(res=> {
                if(this.refreshing){
                    this.refreshing = false
                    this.recordList = []
                }
                this.loading = false;
                this.recordList = [...this.recordList, ...res.data.list]
                if(res.data.list.length < 20) {
                    this.finished = true;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.content {
    padding: 10px 32px;
    background: #0C1913;
    min-height: 100vh;
    .card_top {
        padding: 24px;
        width: 686px;
        height: 668px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(16,43,29,1);
        .title {
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 44px;
        }
        .topupnumber {
            margin-top:  16px;
            display: flex;
            justify-content: space-between;
            .btn {
                color: rgba(139,166,149,1);
                width: 140px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(139,166,149,1);
                text-align: center;
                line-height: 72px;
                font-size: 32px;
            }
            .selectBtn {
                width: 140px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(39,204,127,1);
                background: rgba(39,204,127,0.2);
                text-align: center;
                line-height: 72px;
                font-size: 32px;
                color: rgba(39,204,127,1);;
            }
        }
        .topresult {
            margin-top:16px;
            width: 638px;
            height: 168px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(39,204,127,0.2);
            background: rgba(41,51,45,1);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .expectpay {
                .expectpay_button{
                    background: rgba(39,204,127,0.2);
                    color: rgba(39,204,127,1);
                    text-align: center;
                    border-radius: 8px;
                    padding: 8px 15px;
                    font-size: 16px;margin-left: 10px;
                    font-weight: bold;
                }
                .num {
                    color: rgba(214,255,227,1);
                    font-size: 44px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                }
                .txt {
                    color: rgba(214,255,227,1);
                    font-size: 24px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                    line-height: 60px;
                }
            }
        }
        .inputcontent {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            .inputClass {
                width: 632px;
                background: #29332D;
                border-radius: 16px;
                ::v-deep .van-field__control{
                    color: white;
                }
            }
        }
        .paybtn {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            .btn {
                width: 320px;
                height: 88px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(39,204,127,1);
                color: rgba(32,40,35,1);
                font-size: 36px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 88px;
            }
        }
        .poundage {
            margin-top: 16px;
            color: rgba(139,166,149,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
        }
    }
    .card {
        padding: 24px;
        width: 686px;
        height: 800px;
        border-radius: 24px;
        opacity: 1;
        background: rgba(16,43,29,1);
        .title {
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 400;
            font-family: "PingFang SC";
            line-height: 44px;
        }
        .topupnumber {
            margin-top:  16px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .btn {
                color: rgba(139,166,149,1);
                width: 180px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(139,166,149,1);
                text-align: center;
                line-height: 72px;
                font-size: 32px;
                margin-bottom:0.32rem;
            }
            .selectBtn {
                width: 180px;
                height: 72px;
                border-radius: 16px;
                opacity: 1;
                border: 2px solid rgba(39,204,127,1);
                background: rgba(39,204,127,0.2);
                text-align: center;
                line-height: 72px;
                font-size: 32px;
                color: rgba(39,204,127,1);
                margin-bottom: 0.32rem;
            }
        }
        .topresult {
            margin-top:16px;
            width: 638px;
            height: 168px;
            border-radius: 16px;
            opacity: 1;
            border: 2px solid rgba(39,204,127,0.2);
            background: rgba(41,51,45,1);
            display: flex;
            justify-content: space-around;
            align-items: center;
            .expectpay {
                .num {
                    color: rgba(214,255,227,1);
                    font-size: 44px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                }
                .txt {
                    color: rgba(214,255,227,1);
                    font-size: 24px;
                    font-weight: 500;
                    font-family: "DIN";
                    text-align: center;
                    line-height: 60px;
                }
            }
        }
        .inputcontent {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            .inputClass {
                width: 632px;
                background: #29332D;
                border-radius: 16px;
                ::v-deep .van-field__control{
                color: white;
                }
            } 
        }
        .paybtn {
            margin-top: 48px;
            display: flex;
            justify-content: center;
            .btn {
                width: 320px;
                height: 88px;
                border-radius: 16px;
                opacity: 1;
                background: rgba(39,204,127,1);
                color: rgba(32,40,35,1);
                font-size: 36px;
                font-weight: 700;
                font-family: "PingFang SC";
                text-align: center;
                line-height: 88px;
            }
        }
        .poundage {
            margin-top: 16px;
            color: rgba(139,166,149,1);
            font-size: 28px;
            font-weight: 400;
            font-family: "PingFang SC";
            text-align: center;
        }  
    }
    .offset{
        margin-top: 24px;
    }
    .middlecontent {
        margin-top: 24px;
        margin-bottom: 48px;
        color: rgba(182,217,195,1);
        font-size: 24px;
        font-weight: 700;
        text-align: left;
        font-family: "PingFang SC";
        line-height: 45px;
    }
    .record {
        border-radius: 24px;
        opacity: 1;
        background: rgba(32,40,35,1);
        padding: 24px;
        .title {
            opacity: 1;
            color: rgba(214,255,227,1);
            font-size: 32px;
            font-weight: 700;
            font-family: "PingFang SC";
        }
        .datalist {
            .date {
                margin-top:32px;
                color: rgba(139,166,149,1);
                font-weight: 400;
                text-align: left;
                line-height: 52px;
                font-size: 40px;
            }
            .detail {
                display: flex;
                justify-content: space-between;
                .topnum {
                    margin-top: 32px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    .num {
                        color: rgba(214,255,227,1);
                        font-size: 35px;
                        font-weight: 500;
                        font-family: "DIN";
                    }
                    .txt {
                        color: rgba(139,166,149,1);
                        font-size: 24px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                        text-align: center;
                        line-height: 36px;
                    }
                    .time {
                        color: rgba(139,166,149,1);
                        font-size: 28px;
                        font-weight: 400;
                        font-family: "PingFang SC";
                    }
                }
                .status {
                    display: flex;
                    align-items: center;
                    color: rgba(39,204,127,1);
                    font-size: 20px;
                    font-weight: 400;
                    font-family: "PingFang SC";
                }
            }
        }
    }
}
</style>